import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import { NumbersOnly, stringToInt } from '../Utils/IntegerOnly';
import toast from 'react-hot-toast';

 
const handleChange = (event, row, setSend,lockquantity ) => {
    const inputValue = event.target.value;
    const check_num = NumbersOnly(inputValue)
    

    if(check_num > lockquantity){
        toast.error(`A quantidade máxima é: ${lockquantity}`)
        setSend(lockquantity)
        row['amount'] = lockquantity
    }else{
        setSend(check_num)
        row['amount'] = stringToInt(check_num)
    }

   
  
}

const changeDict = (setIcon, setSend, send, reload) => {
    setIcon(true)

    if(send === '' || send === '0' || send === 0){
        setSend(0)
    }

    reload()
}

const SendBox = ({quantity, row, reload}) => {

    const [icon,setIcon] = useState(true)
    const [send,setSend] = useState(quantity)
    const [lockquantity, setQuantity] = useState(row.quantity)

    const changeToInput = () => {
        setIcon(false)
    }



    return(
        <>
        {
            icon ?
            <Box onClick={changeToInput} className='pencil-icon'>
                {send}
                <CreateIcon/>
            </Box>
            :
            <Box>
                <input className='input-inside' autoFocus={true}  value={send} onChange={(e)=> handleChange(e,row,setSend, lockquantity)} onBlur={()=> changeDict(setIcon, setSend, send, reload)} type="text"/>
            </Box>
        }   
        </>
    )
}

export default SendBox;