import React, {useReducer, useEffect} from 'react'
import DefaultModal from '../Utils/DefaultModal';
import { lockedReducer } from '../Utils/reducers';
import { Box, Tooltip, IconButton} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
import ColorButton from '../Buttons/ColorButton';
import clsx from 'clsx';
import Checkbox from '@mui/material/Checkbox';
import { balanceFunc, validateBalance, validateAmount } from '../Utils/balance';
import SendBox from './sendBox';


const validateRow = (rows) => {
    const newRows = rows.filter(each => each['checked'] === true).length
  
    if(newRows === 0){
        return true
    }

    return false
}

const validateSave = (data) => {

    const response = []
    if (validateRow(data?.rows)){
        response.push(<Box>Selecione um produto.</Box>)
    }else{
        const newRows = data?.rows.filter(each => each['checked'] === true)
        if(validateBalance(newRows,false)){
            response.push(<Box>Não pode haver balanço negativo.</Box>)
        }

        if(validateAmount(newRows)){
            response.push(<Box>Quantidade não pode ser zero.</Box>)
        }
    }

    if (response.length === 0)
        return ''

    return (response)

}

const SearchCodpro = ({data, invoiceList, setNewList, rowsB,setPreviousState,reloadData, setLoader}) => {

    const [state, setState] = useReducer(lockedReducer, {

        defaultRow: null,
        rows:[]

    })

    const handleClose = () => {
        setState({defaultRow: null})
    }

    const validData = validateSave(state)

    const sendProducts = (setInvoice, rows, invoiceList, setState) => {
        const newRows = rows.filter(each => each['checked'] === true)




        const newInvoiceList = [...invoiceList,...newRows].sort((a, b) => a.description.localeCompare(b.description))

     

        if(newRows.length !== 0){
            setInvoice(newInvoiceList)
        }

        setState({defaultRow: null})
        
      
    }


    const columns = [
        {
            field: 'ordernumber',
            headerName: 'Mês/Ano',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'filename',
            headerName: 'Documento',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'product',
            headerName: 'Codpro',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'description',
            headerName: 'Descrição',
            flex: 0.2,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => (
                <Box sx={{display:'flex',flexDirection:'column', width:'100%',justifyContent:'start'}}>
                  
                    {params.value}
                </Box>
              ),

        },
        {
            field: 'amount',
            headerName: 'Quantidade',
            flex: 0.1,
            renderCell: (params) => ( 
                <Box>
                    <SendBox quantity={params.value} row={params.row} reload={()=> setState({...state})}/>
                </Box>
            ),
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
        },
        { field: 'balance', headerName: 'Balanço', flex: 0.1, filterType: 'string',
            renderCell: (props) => (
                <Box sx={{marginLeft:'2.5rem'}}>{balanceFunc(props.row)}</Box>
            ),
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
        },
        {
            field: 'checkbox',
            headerName: '',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => ( 
                <Box>
                    <Tooltip title='Seleciona o produto que será enviado'>
                        <Checkbox checked={params.row.checked} onClick={() => {params.row.checked = !params.row.checked;setState({...state})}}/>
                    </Tooltip>
                </Box>
            ),
        },
    ]

    useEffect(() => {

        // if(state.defaultRow === true){
        //     reloadData(data, setPreviousState, setLoader,'/importacao/') 
        // }
        
      
        let product_list = []

        data.documents.forEach((pedido) => {
            pedido.orderfile_set.forEach((arquivo) => {
                arquivo.productfile_set.forEach((produto) => {
                    product_list.push(produto);
                });
            });
        });

        let filtred_array = product_list.filter(item1 => {
            return !invoiceList.some(item2 => item1.order === item2.order && item1.product === item2.product);
        });

        filtred_array.map(each => each['checked'] = false)
        filtred_array.map(each => each['quantity'] = each.amount)
    
        
        let newRows = filtred_array.sort((a, b) => a.description.localeCompare(b.description))
        setState({rows:newRows})


    }, [state.defaultRow])

    return(
        <>
            
            {
                state.defaultRow && 

                <DefaultModal
                    title={
                        `Produtos`
                    }
                    dialogProps={{
                        sx:{'& .MuiPaper-root': {
                            maxWidth:'unset',
                            maxHeight:'unset',
                            width: '70%',
                            height:'70%',
                            margin:'0px',

                            '@media (min-width: 1600px)': {
                                width: '50%', // Largura para telas maiores
                                height: '80%', // Altura para telas maiores
                            },
                        }}
                    }}
                    handleClose={handleClose}
                    content={
                        <>


                        <Box sx={{display:'flex',flexDirection:'column',gap:'3rem',height:'100%',overflow:'hidden'}}>

                            <Box sx={{flex:1, minHeight:'1rem'}} > 
                                <DataGrid
                                    sx={{
                                        '& .default-app.selected': {
                                            justifyContent:'center',
                                            alignItems:'center',
                                            fontSize:'14px'
                                        },
                                        height:"100%",
                                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                                            justifyContent: 'center',
                                            fontSize:'15px'
                                        },
                                        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                                            color:'black'
                                        }
                                    
                                    }}
                                    className='alterned_grid'
                                    rows={state.rows}                                                                                                                                                                                                                                                                                                                                                                                                                       
                                    columns={columns}
                                    pagination={false}
                                    density='compact'
                                    pageSize={5}
                                    rowHeight={30}
                                />
                            </Box>

                        </Box>
                        
                        </>
                    }
                    action={
                        <>
                            <Tooltip placement="left" title={validData}>
                                <Box>
                                    <ColorButton
                                    disabled={validData}
                                    onClick={() => sendProducts(setNewList, state.rows, rowsB, setState)}
                                    >Enviar
                                    </ColorButton>
                                </Box>
                            </Tooltip>
                        </>
                    }
                />
            }

            <Box sx={{marginBottom:'1rem'}}>
                <Tooltip title="Pesquisa produtos de outros documentos" placement='right'>
                    <ColorButton
                        onClick={() => {setState({ defaultRow: true });} }
                        startIcon={<SearchIcon/>}
                    >
                        Pesquisa
                    </ColorButton>
                </Tooltip>
            </Box>
        </>
    )
}

export default SearchCodpro;
