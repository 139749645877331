import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../Utils/reducers';
import ColorButton from '../Buttons/ColorButton';
import { externalComponent } from '../AppRoutes';
import { Box, Tooltip, IconButton } from '@mui/material'
import DefaultModal from '../Utils/DefaultModal';
import { DataGrid } from '@mui/x-data-grid';
import clsx from 'clsx';
import toast from 'react-hot-toast';
import { api } from '../../services/api';
import SendBox from './sendBox';
import SearchCodpro from './searchCodpro';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { balanceFunc, validateBalance, validateAmount } from '../Utils/balance';


const url = '/importacao/'

const moveProduct = (table,setState,state,row) => {

    let newListA = null
    let newListB = null

    if(table === 'A'){

        const toMoveA = state.listB.filter(each => each.product === row.product && each.order === row.order)
    
        newListB = state.listB.filter(itemB => {
            return !toMoveA.some(itemA => itemA.product === itemB.product && itemA.order === itemB.order);
        });
          
          
        newListA = [...state.listA,...toMoveA].sort((a, b) => a.description.localeCompare(b.description))
        setState({listA:newListA,listB:newListB})

    }else if(table === 'B'){

        const toMoveB = state.listA.filter(each => each.product === row.product && each.order === row.order)
      
        newListA = state.listA.filter(itemA => {
            return !toMoveB.some(itemB => itemB.product === itemA.product && itemB.order === itemA.order);
        });


        newListB = [...state.listB,...toMoveB].sort((a, b) => a.description.localeCompare(b.description));
        setState({listA:newListA,listB:newListB})
       
    }

}

const saveProduct = async (state, setLoader, data, importacao, setInvoice, retrieve, handleClose) => {
    const body = {
        id_importacao: importacao.id,
        orderfile: data.id,
        invoice: state.listB,
        status: 1
    }
  
    setLoader(true)
    const res = await api.post(`${url}save_file/`,body)
    setLoader(false)
    if (res.status === 200) {
        toast.success('Salvo com sucesso')
        setInvoice({invoice:state.listB})
        return
    }

    if (res.status === 400) {
        retrieve(importacao, setInvoice, setLoader,'/importacao/') 
        handleClose()
    }


}

const validateSave = (data) => {

    const response = []
    if (data?.listB.length === 0){
        response.push(<Box>Selecione um produto.</Box>)
    }

    if(validateBalance(data?.listB,false)){
        response.push(<Box>Não pode haver balanço negativo.</Box>)  
    }

    if(validateAmount(data?.listB)){
        response.push(<Box>Quantidade não pode ser zero.</Box>)
    }
        
    if (response.length === 0)
        return ''

    return (response)
}

const ModalProduct = ({data, handleClose, importacao, setInvoice, retrieve, setPreviousLoader}) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        listA: [],
        listB: []
    })


    const validData = validateSave(state)

    const setNewList = (rows) => {
        setState({listB:rows})
    }
  

    useEffect(() => {
        
        const newList = [...data.productfile_set]
        newList.map(each => each['checked'] = false)
        const list = newList.sort((a, b) => a.description.localeCompare(b.description));
        list.map(each => each['quantity'] = each.amount)

        if(data.type === 'PI'){
            setState({listB:list})

        } else if (data.type === 'PI Mãe') {
            setState({listA:list})
        }

            
    }, [importacao.change])

    const columnsA = [
    
        {
            field: 'ordernumber',
            headerName: 'Mês/Ano',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'filename',
            headerName: 'Documento',
            flex: 0.2,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'product',
            headerName: 'Codpro',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'description',
            headerName: 'Descrição',
            flex: 0.2,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'amount',
            headerName: 'Quantidade',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => ( 
                <Box>
                    <SendBox quantity={params.value} row={params.row} reload={()=> setState({...state}) }/>
                </Box>
            ),
        },
        { field: 'balance', headerName: 'Balance', flex: 0.1, filterType: 'number',
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => (
                <Box>
                    {balanceFunc(params.row)}
                </Box>
            )
         },
        {
            field: 'price',
            headerName: 'Preço',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => ( 
                <Box>
                   $ {params.value},00 
                </Box>
            ),

        },
        {
            field: 'checkbox',
            headerName: '',
            width: 75,
            renderCell: (value) => {
                return (
                    <Box sx={{marginLeft:'1rem'}}>
                        <Tooltip title="Aprova o produto">
                            <IconButton><ArrowCircleRightIcon sx={{color:'#479760'}} onClick={() => moveProduct('B',setState,state,value.row)} className='arrowIcon'/></IconButton>
                        </Tooltip>
                    </Box>
                )
            }

        },


    ]

    const columnsB = [

        {
            field: 'checkbox',
            headerName: '',
            width: 50,
            renderCell: (value) => {
                return (
                    <Box sx={{marginRight:'1rem'}}>
                        <Tooltip title="Retira o produto"> 
                            <IconButton><ArrowCircleLeftIcon sx={{color:'#a14949'}} onClick={() => moveProduct('A',setState,state,value.row)} className='arrowIcon'/></IconButton>
                        </Tooltip>
                    </Box>
                )
            }

        },
        {
            field: 'ordernumber',
            headerName: 'Mês/Ano',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'filename',
            headerName: 'Documento',
            flex: 0.2,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'product',
            headerName: 'Codpro',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'description',
            headerName: 'Descrição',
            flex: 0.2,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'amount',
            headerName: 'Quantidade',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => ( 
                <Box>
                    <SendBox quantity={params.value} row={params.row} reload={()=> setState({...state}) }/>
                </Box>
            ),
        },
        { field: 'balance', headerName: 'Balance', flex: 0.1, filterType: 'number',
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => (
                <Box>
                    {balanceFunc(params.row)}
                </Box>
            )
         },
        {
            field: 'price',
            headerName: 'Preço',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => ( 
                <Box>
                   $ {params.value},00 
                </Box>
            ),

        }


    ]


    return(
        <>
        <DefaultModal
                title={
                    `Produtos - Criando Invoice`
                }
                dialogProps={{
                    sx:{'& .MuiPaper-root': {
                        maxWidth:'unset',
                        maxHeight:'unset',
                        width: '95%',
                        height:'95%',
                        margin:'0px',

                        '@media (min-width: 1600px)': {
                            width: '80%', // Largura para telas maiores
                            height: '80%', // Altura para telas maiores
                        },
                    }}
                }}
                handleClose={handleClose}
                content={
                    <>

                    <Box>
                        <SearchCodpro
                        reloadData={retrieve}
                        data={importacao} setPreviousState={setInvoice} setLoader={setPreviousLoader} rowsB={state.listB} invoiceList={[...state.listA,...state.listB]} setNewList={setNewList}/>
                    </Box>

                    <Box sx={{display:'flex',flexDirection:'row',gap:'2rem',height:'100%',overflow:'hidden'}}>

                        <Box sx={{flex:1, minHeight:'1rem'}} > 
                            <h5>Produtos disponíveis</h5> 
                            <DataGrid
                                sx={{
                                    '& .default-app.selected': {
                                        justifyContent:'center',
                                        alignItems:'center',
                                        fontSize:'14px'
                                    },  
                                    height:"30rem",
                                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                                        justifyContent: 'center',
                                        fontSize:'15px'
                                    },
                                    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                                        color:'black'
                                    }
                                
                                }}
                                className='alterned_grid'
                                rows={state.listA}                                                                                                                                                                                                                                                                                                                                                                                                                       
                                columns={columnsA}
                                pagination={false}
                                density='compact'
                                pageSize={5}
                                rowHeight={37}
                            />
                        </Box>
                        
                        <Box sx={{flex:1, minHeight:'1rem'}} >  
                            <h5>Produtos selecionados</h5>
                            <DataGrid
                                sx={{
                                    '& .default-app.selected': {
                                        justifyContent:'center',
                                        alignItems:'center',
                                        fontSize:'14px'
                                    },
                                    height:"30rem",
                                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                                        justifyContent: 'center',
                                        fontSize:'15px'
                                      },
                                    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                                        color:'black'
                                    }
                                  
                                }}
                                className='alterned_grid'
                                rows={state.listB}                                                                                                                                                                                                                                                                                                                                                                                                                       
                                columns={columnsB}
                                pagination={false}
                                density='compact'
                                pageSize={5}
                                rowHeight={37}
                            />
                        </Box>

                    </Box>
                       
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                disabled={validData}
                                onClick={() => {saveProduct(state, setLoader, data, importacao, setInvoice, retrieve, handleClose)}}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>

                    </>
                }
            />
        </>
    )
}

export default ModalProduct;