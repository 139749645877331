export const balanceValue = (balance) => {

   return balance
    
}

export const balanceFunc = (row,type=false) => {

   let newBalance = row.discount - row.amount
   
   if(type === true){
      if(newBalance === 0){
         
         return newBalance
      }
   }

   if(newBalance >= row.balance){

      
      if(type !== true){
         newBalance = row.balance - row.amount
      }
      

      if(type === true){
         if(newBalance < 0){
         
            newBalance = row.discount - row.amount
         }
      }
      
   }

   return newBalance
}

export const validateBalance = (list,type=false) => {
   for (let index = 0; index < list.length; index++) {
      const row = list[index];
      const validate = balanceFunc(row,type)

      if(validate < 0){
         return true
      }

      
   }
}

export const validateAmount = (list) => {
   for (let index = 0; index < list.length; index++) {
      const row = list[index];
      if(row.amount === 0 ){
         return true
      }

   }
}