import React, { useReducer, useContext, useEffect} from 'react'
import { lockedReducer } from '../Utils/reducers';
import { api } from '../../services/api';
import ColorButton from '../Buttons/ColorButton';
import DefaultModal from '../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField, Stepper, Step, StepLabel} from '@mui/material'
import validateData from './validateDataImportacao';
import { externalComponent } from '../AppRoutes';
import MegaAutoComplete from '../Utils/megautocomplete';
import { number2dayweek } from '../Utils/dayjsptbrhelper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import apptheme from '../theme/default';
import { getSession } from '../../contexts/auth';
import Typography from '@mui/material/Typography';
import PreboardingDocuments from './documents';
import ConfirmationDialog from '../Utils/ConfirmationDialog';
import InvoiceModal from './invoiceModal';
import InputFileUpload from './attachment';
import { NumbersOnly, formatCurrency } from '../Utils/IntegerOnly';

const url = '/importacao/'

const submit = async (data, getData, setLoader, handleClose, applyDelete) => {

    setLoader(true)
    let res = null

    if (data.id){

        delete data.file

        if(applyDelete){
            data.status = 1
            data.importer = {'id':'', 'label':''}
            data.shippingagent = {'id':'', 'label':''}
            data.booking = ''
            data.pol = {'id':'', 'label':''}
            data.pod = {'id':'', 'label':''}
            data.armador = {'id':'', 'label':''}
            data.ets = null
            data.eta = null
            data.frete = 0
            await api.patch(`${url}${data.id}/delete_invoice/`)
            await api.post(`${url}remove_invoice/`, data)
           
            const body = {
                id: data.id
            }
            await api.post(`${url}deletefile/`, body)
        }
      
        res = await api.patch(`${url}${data.id}/`, data)
        
    }
        
    else{
        res = await api.post(url, data)
    }

    if (res.status === 201 || res.status === 200) {
        toast.success(`Pré-Embarque ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Pré-Embarque não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const consultFile = async (setState, data, url) => {
    const resInvoice = await api.get(url + `${data.id}/consult_file/`)
    if (resInvoice.status === 200) {
        setState({invoice:resInvoice.data})
    }
}

const loadRetrieve = async (data, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + `${data.id}/`)
    if (res.status === 200) {
        setState({ ...res.data})
    }

    consultFile(setState, data, url)

    setLoader(false)
}

const loadDocuments = async (data, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + `${data.id}/`)
    if (res.status === 200) {
        setState({documents:res.data.documents,change:!data.change})
    }
    setLoader(false)
}

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const backStatus = async (data, getData, setLoader, handleClose, setState) => {
   
    if(data.status === 2 && data.step === 0){
        setState({statusConfirm:true})
    }else{


      
        setLoader(true)

            let permission = true
            const body = {
                id_importacao: data.id,
                invoice: data.invoice,
                orderfile: data.id,
                status: data.status
            }
            const res = await api.post(`${url}save_file/`,body)
            if(res.status === 400){
                permission = false
                loadDocuments(data, setState, setLoader)
            }

            if(permission === true){

                submit(data, getData, setLoader, handleClose, false)
                
                if (res.status === 200) {
                    toast.success('Salvo com sucesso')
                    getData()
                    return
                }
            }

        setLoader(false)
    }
    

}

const generateSteps = (state, setState, setLoader, reloadData, saveDataReset, loadRetrieve) => {

    return [
        {
            label: 'Importação', component: (
                <Box className='item-content-modal noborder' >

                    <Box className='item-profile-row' sx={{alignItems:'flex-end'}}>

                        <MegaAutoComplete
                                key={`exporter2`}
                                value={state.exporter}
                                onChange={(data) => setState({ exporter : data })}
                                noOptionsText={'Digite para procurar um Exportador'}
                                timeoutUrl='/exportador/exportadores/'
                                timeoutParam='label'
                                label='Exportador'
                        />

                        <MegaAutoComplete
                                key={`fabricator2`}
                                value={state.fabricator}
                                onChange={(data) => setState({ fabricator : data })}
                                noOptionsText={'Digite para procurar um Fabricante'}
                                timeoutUrl='/fabricante/fabricadores/'
                                timeoutParam='label'
                                label='Fabricante'
                        />

                    </Box>

                    <Box className='item-profile-row'>

                        <TextField
                                id="num_pi"
                                type="text"
                                label="Número PI"
                                value={state.num_pi}
                                onChange={(e) => { setState({ 'num_pi': e.target.value }) }}
                                fullWidth={false}
                                size='small'
                                inputProps={{
                                    maxLength: 50
                                }}
                        />

                    
                        <DatePicker 
                            slotProps={{ textField: { size: 'small' } }} 
                            label="ETD:"
                            size="small"
                            value={dayjs(state.etd)}  
                            onChange={(e) => { setState({ 'etd': e.format('YYYY-MM-DD') }) }}  
                            format='DD/MM/YYYY'
                            dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                            ampm={false}
                        />
                        
                    </Box>
                    
                </Box>
            ),
        },
        {   
            label: 'Pré-Embarque', component: (
                <Box className='item-content-modal noborder'>

                    <Box className='item-profile-row'>

                        <MegaAutoComplete
                                value={state.importer}
                                onChange={(data) => setState({ importer : data })}
                                noOptionsText={'Digite para procurar um Importador'}
                                timeoutUrl='/importador/complete/'
                                timeoutParam='label'
                                label='Importador'
                        />

                    </Box>

                    <Box className='item-profile-row'>

                        <MegaAutoComplete
                                value={state.shippingagent}
                                onChange={(data) => setState({ shippingagent : data })}
                                noOptionsText={'Digite para procurar um Agente'}
                                timeoutUrl='/agente_maritimo/complete/'
                                timeoutParam='label'
                                label='Agente Marítimo'
                        />

                    </Box>

                    <Box className='item-profile-row'>

                        <MegaAutoComplete
                            value={state.armador}
                            onChange={(data) => setState({ armador : data })}
                            noOptionsText={'Digite para procurar um Armador'}
                            timeoutUrl='/armador/complete/'
                            timeoutParam='label'
                            label='Armador'
                        />  

                    </Box>

                    <Box className='item-profile-row'>

                        <TextField
                            id="booking"
                            type="text"
                            label="Booking"
                            value={state.booking}
                            onChange={(e) => { setState({ 'booking': e.target.value }) }}
                            fullWidth={false}
                            size='small'
                            inputProps={{
                                maxLength: 50
                            }}
                        />

                    </Box>

                    <Box className='item-profile-row'>

                        <MegaAutoComplete
                            value={state.pol}
                            onChange={(data) => setState({ pol : data })}
                            noOptionsText={'Digite para procurar um Porto'}
                            timeoutUrl='/porto/complete/'
                            timeoutParam='label'
                            label='POL'
                        />

                        <MegaAutoComplete
                            value={state.pod}
                            onChange={(data) => setState({ pod : data })}
                            noOptionsText={'Digite para procurar um Porto'}
                            timeoutUrl='/porto/complete/'
                            timeoutParam='label'
                            label='POD'
                        />

                    </Box>

                  
                    <Box className='item-profile-row'>

                        <DatePicker 
                            slotProps={{ textField: { size: 'small' } }} 
                            label="ETS:"
                            size="small"
                            value={dayjs(state.ets)}  
                            onChange={(e) => {setState({ 'ets': e?.format('YYYY-MM-DD') ?? '' })}} 
                            format='DD/MM/YYYY'
                            dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                            ampm={false}
                        />

                        <DatePicker 
                            slotProps={{ textField: { size: 'small' } }} 
                            label="ETA:"
                            size="small"
                            value={dayjs(state.eta)}  
                            onChange={(e) => {setState({ 'eta': e?.format('YYYY-MM-DD') ?? '' })}}
                            format='DD/MM/YYYY'
                            dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                            ampm={false}
                        />

                    </Box>

                    <Box className='item-profile-row'>

                        <TextField
                            id="frete"
                            type="text"
                            label="Frete"
                            value={formatCurrency(state.frete)}
                            onChange={(e) => { setState({ 'frete': NumbersOnly(e.target.value) }) }}
                            fullWidth={false}
                            size='small'
                            inputProps={{
                                maxLength: 50
                            }}
                        />

                        <InputFileUpload id={state.id} setState={setState}/>

                    </Box>

                    <Box sx={{display:'flex',flexDirection:'column'}} className='item-profile-row'>

                        {state.invoice.length === 0 ? (
                            
                            <Box> 
                                <Typography>Pedidos:</Typography>
                                <br/>
                                <PreboardingDocuments state={state} setState={setState} saveDataReset={saveDataReset} retrieve={loadRetrieve} />
                            </Box>
                        ) : (
                          

                        <InvoiceModal
                            invoice={state.invoice}
                            data={state}
                            setLoader={setLoader}
                            reloadData={reloadData}
                            setPreviousState={setState}
                            type={false}
                            retrieve={loadRetrieve}
                        />

                        )}
                        
                    </Box>

                </Box>
            )
        }
    ]
}

const EditPreBoarding = ( {data, handleClose, reloadData} ) => {

    const user = getSession()

    const { setLoader } = useContext(externalComponent)


    const [state, setState] = useReducer(lockedReducer, {
        exporter: '',
        fabricator:'',
        num_pi: '',
        etd:'',
        status:null,

        importer: '',
        shippingagent: '',
        armador: '',
        booking:'',
        pol: '',
        pod: '',
        eta: '',
        ets: '',
        frete: 0,
        documents:[],

        invoice:[],
        file:null,
        invoiceModal:null,
        dataReset:null,
        deleteRow:null,
        change:true,

        statusConfirm:null,
        step: 0,
        ...data,
        id: data.id ? data.id : 0
    })

    const saveDataReset = () => {
        setState({dataReset:JSON.stringify(state.documents)})
    }

    const validData = validateData(state)

    useEffect(() => {
        if (data.id){
            setState({step:maxStep})
            loadRetrieve(data, setState, setLoader)
        }
        else{
            setState({ loaded: true })
        }
    }, [setLoader, data.id])


    const stepsArray = generateSteps(state, setState, setLoader, reloadData, saveDataReset, loadDocuments)
    const maxStep = stepsArray.length - 1
    
    return(
        <>

        {
            state.statusConfirm && 

            <ConfirmationDialog
                    content={`Deseja voltar para o status inicial ?`}
                    handleClose={() => setState({ statusConfirm: null })}
                    onConfirm={() => submit(state, reloadData, setLoader, handleClose, true)}
            />
        }

        <DefaultModal
                title={
                    `${getTitle(state)} Pré-Embarque`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Stepper activeStep={state.step}>
                            {stepsArray.map((step, index) => (
                                <Step key={step.label} >
                                    <StepLabel sx={{
                                        '.MuiStepIcon-root.Mui-active': { color: apptheme.primaryColor },
                                        '.MuiStepIcon-root.Mui-completed': { color: apptheme.primaryColor }
                                    }}>
                                        {step.label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box className='item-content-modal noborder' sx={{ paddingTop: '1rem' }}>
                            {stepsArray[state.step].component}
                        </Box>
                    </>
                }
                action={
                    <>

                        {
                        (state.step !== 0) && 
                        <Box sx={{marginLeft:'0!important'}}>
                            <ColorButton
                                onClick={() => setState({ step: state.step - 1 })}
                                >
                                    Anterior
                            </ColorButton>
                        </Box>
                    }

                    
                    {
                        state.step !== maxStep && 
                            <Tooltip placement="left">
                                <Box sx={{marginLeft:'0!important'}}>
                                    <ColorButton

                                            onClick={() => {   setState({ step: state.step + 1 })}}
                                            >
                                                Próximo
                                    </ColorButton>
                                </Box>
                            </Tooltip>
                    }

                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => backStatus(state, reloadData, setLoader, handleClose, setState)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                       
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditPreBoarding;