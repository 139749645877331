const IntegerOnly = (value, min, max, returnString=false) => {
    const newvalue = value.replace(new RegExp(/[^\d]/, "ig"), "")
    if (returnString) return newvalue
    if (Number(newvalue) < min)
        return Number(min)
    if  (Number(newvalue) > max)
        return Number(max)
    return Number(newvalue)
}

export const FloatOnly = (value, min, max, returnString=false) => {
    const newvalue = value.replace(/[^-\d.]+/g, "")
    return newvalue
}

export const NumbersOnly = (value) => {
    return String(IntegerOnly(value, '', '', true))
}

export const formatCurrency = (value) => {
    // Verifica se o valor é uma string, se não, converte para string
    const stringValue = String(value || '');
    // Remove tudo que não é número
    const numberValue = stringValue.replace(/[^0-9]/g, '');

    // Se numberValue for vazio, retorna R$0,00
    if (!numberValue) {
        return '$0,00';
    }

    // Converte o valor para inteiro
    const amount = parseInt(numberValue, 10);

    // Formata o número como moeda em dólar
    const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount / 100); // Divide por 100 para a formatação correta

    // Substitui o ponto pela vírgula
    return formattedValue.replace('.', ',');
};

export const stringToInt = (str) => {
    const parsedInt = parseInt(str, 10); // Specify radix 10 to ensure decimal conversion
    return isNaN(parsedInt) ? 0 : parsedInt;
}

export default IntegerOnly